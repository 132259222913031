import React from 'react'




const Bulletin = props => {
    return (
        <div className='Bulletin-Container NoBars'>
            <h2>Bulletin and Announcements</h2>

            <ul className='NoBars'>   
          
            <li>

                <aside  style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }} >
                <a href={`/assets/ChildrensHalloween.pdf`} target='blank'>View</a>

                    <h3>Child Halloween Party 10-27-2024</h3>

                    </aside>

                </li>
            

                <li>

                <aside  style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }} >
                <a href={`/assets/AdultHalloween.pdf`} target='blank'>View</a>

                    <h3>Adult Halloween Party 10-26-2024</h3>

                    </aside>

                </li>
            

            
                <li>
                <img src="/assets/YardSale.jpg" alt="" />

                <aside  style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }} >
                <a href={`/assets/YardSale.jpg`} target='blank'>View</a>

                    <h3>Yard Sale 10-12-2024</h3>

                    </aside>

                </li>
            

                <li>
                    {/* <img src="/assets/images/Curillo.png" alt="" /> */}

                    
                <aside  style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }} >

                <a href="/assets/images/Curillo.png" target='blank'>View</a>
                    <h3>Cursillo Spiritual Retreat</h3>
                    </aside>

                </li>
            

            </ul>

        </div>
    )
}

export default Bulletin