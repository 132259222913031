import React from 'react';

function Homily() {

    
    return (
        <div className='SundayHomily'>
            <main>
                <a className='MainBTN' href={`/assets/Homily/2024-10-6.pdf`} download>📄Read Homily  </a>

                <a className='MainBTN' href={`/assets/Bulletin/2024/2024-10-6.pdf`} download>📄View Bulletin  </a>
              

            </main>

        </div>
    );
}

export default Homily;